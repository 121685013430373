import { toast } from 'react-toastify';
import * as aws from './aws';
import { ApiResponse, request } from './base';

export interface FinanceListFetchParam {
  page?: number;
  pageSize?: number;
  from?: string;
  to?: string;
  name?: string;
  uploaderId?: number;
  adjusterId?: number;
}

export const fetchFinanceList = async (params: FinanceListFetchParam, sort?: any) => {
  const data: { sort?: any } = {};
  if (sort) {
    data.sort = sort;
  }

  const response = await request({
    method: 'GET',
    url: '/finance/list',
    data,
    params: {
      ...params,
      page: 0,
      pageSize: 25,
    },
  });

  return response;
};

export const getFinanceUploaderAvatarUrl = async (finances: any[]) => {
  const tasks = finances.map(async finance => {
    if (finance.uploader && finance.uploader.avatar) {
      const url = await aws.getDownloadFileUrl(finance.uploader.avatar);
      if (url) {
        finance.uploader.avatar = url;
      }
    }
  });

  return await Promise.all(tasks);
};

export const getFinanceAdjusterAvatarUrl = async (finances: any[]) => {
  const tasks = finances.map(async finance => {
    if (finance.adjuster && finance.adjuster.avatar) {
      const url = await aws.getDownloadFileUrl(finance.adjuster.avatar);
      if (url) {
        finance.adjuster.avatar = url;
      }
    }
  });

  return await Promise.all(tasks);
};

export const uploadFinanceFile = async (file: any, adjusterId?: string) => {
  const data = {
    files: [
      {
        name: file.name,
        type: file.type ? file.type : file.name.endsWith('.xls') ? 'application/vnd.ms-excel' : '',
        size: file.size,
      },
    ],
  };
  if (adjusterId) {
    data.adjusterId = adjusterId;
  }
  const response = await request({
    method: 'POST',
    url: '/finance',
    data,
  });
  if (response.isSuccess) {
    const fileResponse = response.data[0];
    const uploadedFile: aws.UploadedFile = {
      originalFile: file,
      name: file.name,
      type: file.type,
      size: file.size,
    };
    const uploadResponse = await aws.uploadFile(
      {
        url: fileResponse.url,
        fields: fileResponse.fields,
      },
      uploadedFile
    );

    if (!uploadResponse.isSuccess) {
      return uploadResponse;
    }
  }

  return response;
};

export const deleteFinancialFile = async (fileId: number) => {
  return await request({
    method: 'DELETE',
    url: `/finance/${fileId}`,
  });
};

export const fetchAdjusterList = async () => {
  return await request({
    method: 'GET',
    url: '/finance/adjusters',
  });
};

export const fetchAdminList = async () => {
  return await request({
    method: 'GET',
    url: '/finance/admins',
  });
};

const parseResponseAndShowDownload = (response: ApiResponse, defaultFileName = '') => {
  let filename = '';
  // var disposition = xhr.getResponseHeader('Content-Disposition');
  const disposition = response.headers['Content-Disposition'];
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  if (!filename) {
    filename = defaultFileName;
  }
  // const type = response.headers['Content-Type'];

  const blob = response.data;
  // if (typeof File === 'function') {
  //     try {
  //         blob = new File([this.response], filename, { type: type });
  //     } catch (e) { /* Edge */ }
  // }
  // if (typeof blob === 'undefined') {
  //     blob = new Blob([this.response], { type: type });
  // }

  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const URL = window.URL || window.webkitURL;
    const downloadUrl = URL.createObjectURL(blob);

    if (filename) {
      // use HTML5 a[download] attribute to specify filename
      const a = document.createElement('a');
      // safari doesn't support this yet
      if (typeof a.download === 'undefined') {
        window.location = downloadUrl;
      } else {
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
      }
    } else {
      window.location = downloadUrl;
    }

    setTimeout(() => {
      URL.revokeObjectURL(downloadUrl);
    }, 100);
  }
};

const readBlob = (blob: any) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = () => {
      reader.abort();
      reject();
    };
    reader.readAsText(blob);
  });

export const exportFinanceList = async (params: FinanceListFetchParam, sort?: any) => {
  const data: { sort?: any } = {};
  if (sort) {
    data.sort = sort;
  }
  const response = await request({
    method: 'GET',
    url: '/adjuster/finances/export',
    responseType: 'blob',
    headers: {
      Accept: '*/*',
    },
    params: {
      ...params,
      page: 0,
      pageSize: 0,
      exportType: 'csv',
    },
    data,
  });

  if (!response.isSuccess) {
    if (response.data.msg) {
      toast.dismiss();
      toast.error(response.data.msg);
      return response;
    }
    const responseData = await readBlob(response.data);
    try {
      const jsonData = JSON.parse(responseData);
      if (jsonData && jsonData.errors && jsonData.errors.length > 0) {
        return {
          error: jsonData.errors[0].msg,
        };
      }
    } catch (err) {
      console.error('exportFinanceList err: ', err);
    }

    return {
      error: '',
    };
  }

  parseResponseAndShowDownload(response, `Finance_List_${new Date().toISOString()}.csv`);
  return true;
};

export const matchAdjutser = async (fileName: string) => {
  return await request({
    method: 'POST',
    url: '/finance/match-adjuster',
    data: {
      fileName,
    },
  });
};
